<template>
  <v-dialog v-model="dialog" width="75%" height="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain v-bind="attrs" v-on="on" @click="dialog = true">
        <v-icon small class="mr-2" :title="$t('activityEdit')"
          >mdi-square-edit-outline</v-icon
        >
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t('addActivity') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <activity-form
        v-model="model"
        @save="onSave"
        @cancel="close"
        @delete="onDelete"
      />
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script>
import activityApi from '@/services/api/activity'
import activityForm from './activity-form.vue'

export default {
  components: { activityForm },
  props: {
    title: { type: String, default: 'Add Activity' },
    value: { type: Object, default: () => ({}) }
  },
  data() {
    return {
      dialog: false,
      model: {}
    }
  },
  watch: {
    value() {
      this.model = {
        ...this.value
      }
    }
  },
  mounted() {
    this.model = {
      ...this.value
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    async onDelete(entity) {
      const response = await activityApi.delete(entity.id)
      this.close()
      this.$emit('deleted', response.data)
    },
    async onSave(entity) {
      const response = await activityApi.put(entity.id, { ...entity })
      this.close()
      this.$emit('saved', response.data)
    }
  }
}
</script>

<style></style>
