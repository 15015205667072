<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    :nudge-right="40"
    :nudge-down="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable @input="onInput">
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false">
        {{ $t('cancel') }}
      </v-btn>
      <v-btn text color="primary" @click="$refs.menu.save(date)">
        {{ $t('ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { DateTime } from 'luxon'
import { format } from '@/services/datetime'

export default {
  props: {
    label: { type: String, default: () => this.$t('date') },
    value: { type: String, default: null }
  },
  data() {
    return {
      menu: false,
      date: null,
      valueText: ''
    }
  },
  watch: {
    value(newVal) {
      this.parseValue(newVal)
    }
  },
  methods: {
    onInput() {
      this.$refs.menu.save(this.date)
      this.menu = false
      this.$emit('input', this.date)
    },
    parseValue(newVal) {
      if (!newVal) {
        this.date = null
      } else {
        const dt = DateTime.fromISO(newVal)
        if (dt.isValid) {
          this.date = dt.toISODate()
        }
      }
      this.setValueText()
    },
    setValueText() {
      if (!this.datetime) {
        this.valueText = null
        return
      }

      this.valueText = format.toLocal(this.datetime, 'YYYY-MM-DD')
    }
  }
}
</script>

<style></style>
