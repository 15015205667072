<template>
  <span>
    <v-menu bottom left offset-y pa-3>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item-group>
          <v-list-item v-if="!person.isDeleted" @click="confirmDelete">
            <v-list-item-content class="text-right">
              <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <confirmation
      v-model="dialog"
      :title="$t('delete')"
      :text="$t('personDeleteConfirm')"
      :confirm-text="$t('deleteYes')"
      :cancel-text="$t('cancel')"
      @confirm="deletePerson"
      @cancel="dialog = false"
    />
    <alert v-model="alert" :text="alertMessage" @close="alert = false" />
  </span>
</template>

<script>
import { mapActions } from 'vuex'
import confirmation from '@/components/dialogs/confirmation2'
import alert from '@/components/dialogs/alert'
import personApi from '@/services/api/person'

export default {
  components: {
    alert,
    confirmation
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      alert: false,
      alertMessage: '',
      dialog: false
    }
  },
  methods: {
    ...mapActions('messages', ['addError']),
    async confirmDelete() {
      try {
        await personApi.deleteConfirm(this.person.id)
      } catch (err) {
        this.alertMessage = this.$t('personCanNotDelete')
        this.alert = true
        return
      }

      this.dialog = true
    },
    async deletePerson() {
      try {
        await personApi.delete(this.person.id)
        this.$router.push({ name: 'people-index' })
      } catch (err) {
        alert(err.message)
      }
    },
    async changeProfilePicture() {}
  }
}
</script>
