import apiClient from '@/services/api/clients/api-client.js'
import { API_URL } from '@/config'

const path = `${API_URL}/activity`

const client = apiClient.build(path)

client.forEntity = (id, options) => {
  const searchPath = `${path}/entity/${id}`
  return client.__api
    .get(searchPath, { params: options })
    .then(response => response)
}

export default client
