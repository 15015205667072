<template>
  <div class="mb-4">
    <v-card flat>
      <v-toolbar flat color="gray lighten-4">
        <v-toolbar-title>{{ $t('timeline') }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <addActivity
          :entity-id="person.id"
          entity-type="person"
          @saved="onActivitySaved"
        />
      </v-toolbar>
      <v-data-table
        :disable-pagination="true"
        :headers="headers"
        :items="items"
        :items-per-page="5000"
        :hide-default-footer="true"
        :no-data-text="$t('noActivities')"
        class="elevation-1"
        w-full
      >
        <template v-slot:item.localDate="{ item }">
          {{ item.localDate | dateFormatDtg }}
        </template>

        <template v-slot:item.actions="{ item }">
          <div class="text-right">
            <edit-activity
              :value="item"
              @saved="loadActivities"
              @deleted="loadActivities"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-divider class=""></v-divider>
  </div>
</template>
<script>
import activityApi from '@/services/api/activity'
import addActivity from '@/components/activity/add-activity.vue'
import editActivity from '@/components/activity/edit-activity.vue'

export default {
  components: {
    addActivity,
    editActivity
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      headers: [
        {
          text: this.$t('date'),
          align: 'start',
          sortable: true,
          value: 'localDate'
        },
        { text: this.$t('event'), value: 'activityType' },
        { text: this.$t('contact'), value: 'contact' },
        { text: this.$t('notes'), value: 'notes' },
        { text: '', value: 'actions', sortable: false }
      ],
      meta: {
        paging: {
          page: 1
        }
      },
      items: [],
      tableOptions: {
        page: 1,
        itemsPerPage: 55,
        sortBy: ['localDate'],
        sortDesc: [true]
      }
    }
  },
  mounted() {
    this.loadActivities()
  },
  methods: {
    async loadActivities() {
      const response = await activityApi.forEntity(this.person.id)
      this.items = response.data.results
      this.meta = response.data.meta
    },
    edit(item) {
      console.log('edit', item)
    },
    onActivitySaved(activity) {
      this.items.push(activity)
    }
  }
}
</script>
