<template>
  <v-dialog v-model="show" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">
        {{ title }}
      </v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn tile large color="primary" @click="onClose">
          {{ $t('ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: function() {
        return this.$t('alert')
      }
    },
    text: { type: String, default: '' },
    value: Boolean
  },
  data() {
    return {}
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.show = val
    }
  },
  methods: {
    onClose() {
      this.$emit('close')
      this.show = false
    }
  }
}
</script>

<style></style>
