<template>
  <div class="mb-4">
    <v-card flat>
      <v-toolbar flat color="gray lighten-4">
        <v-toolbar-title>{{ $t('mediaHeader') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <media-upload :personId="person.id" @media-added="onMediaAdded" />
      </v-toolbar>

      <div v-if="hasNoMedia" class="text-center mb-0 text-sm-body-2">
        {{ $t('noMedia') }}
      </div>

      <div class="d-flex">
        <v-card flat class="p-0">
          <v-card-text>
            <v-row>
              <v-col v-for="media in person.media" :key="media.id">
                <media-card
                  :media="media"
                  :allowDelete="true"
                  :person="person"
                  @keyImageChanged="onKeyImageChanged"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-list>
        <v-list-group
          v-for="collection in person.collections"
          :key="collection.id"
          :value="true"
          :prepend-icon="'mdi-folder-table'"
          class="bg-green"
        >
          <template v-slot:activator>
            <v-list-item-title>
              {{ collection.collectionShortId }}:
              {{ collection.collectionNumber }}
              <v-btn
                icon
                :to="{
                  name: 'collection-detail',
                  params: { collectionId: collection.id }
                }"
                target="_blank"
                :title="$t('collectionView')"
                @click.stop
              >
                <v-icon small>mdi-open-in-new</v-icon>
              </v-btn>
            </v-list-item-title>
          </template>

          <v-list-item>
            <v-card flat class="p-0">
              <v-card-text>
                <v-row>
                  <v-col v-for="media in collection.media" :key="media.id">
                    <media-card
                      :media="media"
                      :person="person"
                      @keyImageChanged="onKeyImageChanged"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-list-item>
        </v-list-group>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import MediaUpload from './media-upload.vue'
import MediaCard from './media-card.vue'

export default {
  components: {
    MediaCard,
    MediaUpload
  },
  props: {
    person: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cardWidth: 150,
      cardHeight: 125
    }
  },
  computed: {
    hasNoMedia() {
      return (
        this.person.media.length === 0 && this.person.collections.length === 0
      )
    }
  },
  watch: {
    person() {}
  },
  mounted() {},
  methods: {
    onMediaAdded(files) {
      this.person.media.push(...files)
      this.$emit('media-added', files)
    },
    onKeyImageChanged(media) {
      this.person.keyImage = media
    }
  }
}
</script>
