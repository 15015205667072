<template>
  <div>
    <v-card flat tile>
      <v-card-text>
        <v-alert v-if="errors.length" type="error" :value="true">
          <ul>
            <li v-for="error in errors" :key="error">{{ error }}</li>
          </ul>
        </v-alert>
        <v-form ref="form" v-model="valid">
          <v-row no-gutters>
            <v-col cols="12">
              <v-select
                v-model="model.activityType"
                :label="`${$t('activityType')}*`"
                :items="activityTypes"
                :disabled="!allowEdit"
                :rules="activityRules"
              ></v-select>

              <date-picker
                :label="`${$t('date')}*`"
                required
                v-model="model.localDate"
                :rules="[v => !!v || $t('activityDateRequired')]"
              />

              <v-text-field
                :label="`${$t('contact')}`"
                v-model="model.contact"
                :rules="contactRules"
                :disabled="!allowEdit"
              ></v-text-field>

              <v-textarea
                name="notes"
                :label="`${$t('notes')}`"
                auto-grow
                v-model="model.notes"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="model.id" plain color="error" @click="onDelete">
          {{ $t('delete') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn plain color="default" @click="onCancel">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn tile large color="primary" @click="onSave" :disabled="!valid">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash'
import datePicker from '@/components/datetime/date-picker.menu.vue'

export default {
  components: { datePicker },
  props: {
    allowEdit: {
      type: Boolean,
      default: true
    },
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activityTypes: [],
      errors: [],
      model: {},
      valid: false,
      activityRules: [v => !!v || this.$t('required')],
      contactRules: [
        v => (v?.length || 0) <= 225 || this.$t('maxLength', { length: 225 })
      ],
      nameRules: [
        v => !!v || this.$t('required'),
        v => (v && v.length <= 255) || this.$t('maxLength', { length: 255 })
      ]
    }
  },
  computed: {
    isNew() {
      return this.model.id ? true : false
    }
  },
  mounted() {
    this.model = _.cloneDeep(this.value)
    this.activityTypes = this.$t('activityTypes').split(',')
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.model = _.cloneDeep(newVal)
      } else {
        this.model = {}
      }
    }
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete', this.model)
    },
    onSave() {
      this.$refs.form.validate()
      if (!this.valid) {
        return
      }
      this.$emit('save', { ...this.model })
    }
  }
}
</script>
