<template>
  <v-dialog v-model="dialog" width="75%" height="90%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn plain v-bind="attrs" v-on="on" @click="dialog = true"
        >{{ $t('add') }}
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat dark color="primary">
        <v-toolbar-title>{{ $t('addActivity') }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <activity-form v-model="model" @save="onSave" @cancel="close" />
      <v-spacer />
    </v-card>
  </v-dialog>
</template>

<script>
import activityApi from '@/services/api/activity'
import activityForm from './activity-form.vue'

export default {
  components: { activityForm },
  props: {
    title: { type: String, default: 'Add Activity' },
    entityId: { type: String, required: true },
    entityType: { type: String, required: true }
  },
  data() {
    return {
      dialog: false,
      model: {}
    }
  },
  mounted() {
    this.model = {
      entityId: this.entityId,
      entityType: this.entityType
    }
  },
  methods: {
    close() {
      this.dialog = false
    },
    async onSave(entity) {
      console.log('onSave activity', entity)

      const response = await activityApi.post({ ...entity, ...this.model })
      this.close()
      this.$emit('saved', response.data)
    }
  }
}
</script>

<style></style>
